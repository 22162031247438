import * as React from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { NavBar } from "./components/ui/navbar/Navbar";
import Home from "./components/pages/Home";
import Dashboard from "./components/pages/Dashboard";
import PageNotFound from "./components/pages/PageNotFound";
PageNotFound;
import ManageConnections from "./components/pages/ManageConnections";
import Verification from "./components/pages/Verification";
import Analytics from "./components/pages/Analytics";
import UserProfile from "./components/pages/UserProfile";
import AdminDashboard from "./components/pages/AdminDashboard";
import ManageSessions from "./components/pages/ManageSessions";
import ForgotPassword from "./components/pages/ForgotPassword";

export default function FrontendApp() {
  const location = useLocation();
  // alert(location.pathname)https://mentor-portal-backend.azurewebsites.net
  //https://mentor-portal-backend.azurewebsites.net
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/verification' element={<Verification />} />
        <Route path='/connections' element={<ManageConnections />} />
        <Route path='/sessions' element={<ManageSessions />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/analytics' element={<Analytics />} />
        <Route path='/profile/:id' element={<UserProfile />} />
        <Route path='/admin' element={<AdminDashboard />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </div>
  );
}
