import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import logo from "./logo.png";
import { MentorCardProps, UserData } from "./interfaces";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useData } from "../utils/context/DataContext";
import axios, { AxiosError } from "axios";
import {
  DisplayClassification,
  DisplayClassificationText,
} from "../Common/DisplayClassification";
import { getCookie, setCookie } from "../utils/cookies";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ConnectButton = ({
  connectionObject,
  mentee_id,
  mentor_id,
  setConnectionObject,
}: {
  connectionObject: {
    connection_request_id: string | null;
    status: string | null;
  };
  mentee_id: string;
  mentor_id: string;
  setConnectionObject: Function;
}) => {
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });

  const handleConnectionRequest = async (): Promise<{
    id: string;
    mentor_id: string;
    mentee_id: string;
    created_date: Date;
    last_edited_date: Date;
    status: string;
  } | null> => {
    try {
      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest`,
        {
          method: "POST",
          data: { mentor_id, mentee_id },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return connectionPostData;
      // setConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
      return null;
    }
  };

  const deleteConnectionRequest = async (id: string) => {
    try {
      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // setConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  const handleClick = async (add = true, id) => {
    if (!add && id) {
      const answer = confirm(
        "Are you sure you want to remove this connection?"
      );
      if (answer) {
        setConnectionObject({ status: null, connection_request_id: null });
        deleteConnectionRequest(id);
      }
      return;
    } else {
      setConnectionObject({ status: "REQUESTED", connection_request_id: null });
      const requestData = await handleConnectionRequest();
      setConnectionObject({
        ...connectionObject,
        status: requestData?.status,
        connection_request_id: requestData?.id,
      });
    }
  };

  if (!connectionObject.connection_request_id) {
    return (
      <Button
        onClick={() => {
          handleClick(false, null);
        }}
        style={{ backgroundColor: "rgba(55, 133, 191, 0.94)" }}
      >
        Request Connection
      </Button>
    );
  }

  if (connectionObject.status === "DENIED") {
    return (
      <Button variant='outline-danger' disabled>
        Connection Denied
      </Button>
    );
  }

  if (connectionObject.status === "ACCEPTED") {
    return (
      <div>
        <Button
          variant='outline-warning'
          className='text-dark'
          onClick={() => {
            handleClick(false, connectionObject.connection_request_id ?? "");
          }}
        >
          Disconnect
        </Button>
      </div>
    );
  }

  if (
    connectionObject.status === "REQUESTED" &&
    connectionObject.connection_request_id
  ) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className='text-success mr-2'>Connection Requested</span>
        <Button
          onClick={() => {
            handleClick(false, connectionObject.connection_request_id);
          }}
          variant='outline-danger'
        >
          undo
        </Button>
      </div>
    );
  }
};

const ApprovalButton = ({ mentorData }: { mentorData: UserData }) => {
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });
  const [approved, setApproved] = useState(mentorData.approved);

  const approveAccount = async (id: string, status: boolean) => {
    try {
      setApproved(status);
      const { data } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${id}`,
        {
          method: "PATCH",
          data: {
            approved: status.toString(),
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  if (approved) {
    return (
      <>
        <span className='text-dark mr-2'>Approved</span>
        <Button
          onClick={() => {
            approveAccount(mentorData.id, false);
          }}
          variant='danger'
        >
          undo
        </Button>
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        approveAccount(mentorData.id, true);
      }}
      variant='warning'
    >
      Approve
    </Button>
  );
};

export default function MentorCardTwo({
  mentorData,
  isAdmin,
}: MentorCardProps) {
  const { data } = useData();
  const handleClick = () => {
    window.location.href = `/profile/${mentorData.id}`;
  };

  const [fetchedImageUrl, setFetchedImageUrl] = useState("");

  const [showMoreBio, setShowMoreBio] = useState(false);

  const [connectionObject, setConnectionObject] = useState({
    status: mentorData.status,
    connection_request_id: mentorData.connection_request_id,
  });

  const truncateText = (text, maxLength) => {
    if (typeof text === "string") {
      return text.length <= maxLength
        ? text
        : text.slice(0, maxLength - 3) + "...";
    }
    return text;
  };

  const getImageUrl = async () => {
    try {
      const imagesCached = await getCookie("cache-images");
      if (imagesCached) {
        setFetchedImageUrl(mentorData.profile_image);
      } else {
        const expirationTime = new Date(Date.now() + 30 * 60 * 1000); // 30 minutes from now
        await setCookie("cache-images", "cached", expirationTime);
        setFetchedImageUrl(`${mentorData.profile_image}?${Math.random()}`);
      }
    } catch (e) {
      console.log("cache-images error");
      console.log(e);
      setFetchedImageUrl(`${mentorData.profile_image}?${Math.random()}}`);
    }
  };

  useEffect(() => {
    getImageUrl();
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <ResponsiveDiv>
      <Container>
        <Row style={{ margin: "0px -20px" }}>
          <Col md={12}>
            <Row>
              <ImageCol sm={3}>
                <ImageContainer
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={handleClick}
                >
                  {mentorData.profile_image ? (
                    <ProfileImg
                      src={fetchedImageUrl || mentorData.profile_image}
                    />
                  ) : (
                    <img
                      style={{ marginTop: 10 }}
                      src={logo}
                      width={"75%"}
                      height={"75%"}
                    />
                  )}
                  <ContentContainer>
                    <HeaderTitle>{`${mentorData.first_name} ${mentorData.last_name}`}</HeaderTitle>
                    <DisplayClassificationTextStyled>
                      {truncateText(DisplayClassificationText(mentorData), 80)}
                    </DisplayClassificationTextStyled>
                    <StyledBio>
                      {mentorData.blurb ? mentorData.blurb : "No Bio Available"}
                    </StyledBio>
                  </ContentContainer>
                </ImageContainer>
                {isAdmin && (
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ApprovalButton mentorData={mentorData} />
                  </div>
                )}
              </ImageCol>
            </Row>
          </Col>
        </Row>
      </Container>
    </ResponsiveDiv>
  );
}

// Styled Components

const ResponsiveDiv = styled.div`
  overflow-x: hidden;
  padding: 0px 0px;
  margin-left: -10px;

  // @media (max-width: 768px) {
  //   padding: 0 10px;
  //   margin-left: 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
`;

const ContentContainer = styled.div`
  position: absolute;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  width: 100%;
  margin-left: 10px;
`;

const DisplayClassificationTextStyled = styled("p")`
  color: white;
  margin: 5px 0;
  align-self: flex-start;
  font-size: 12px;
`;

const HeaderTitle = styled("h4")`
  cursor: pointer;
  color: #ffffff;
  margin: 0;
  align-self: flex-start;
  font-size: 20px;
  z-index: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
`;

const ImageCol = styled(Col)`
  // justify-content: center;
  margin-left: -10px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const ImageContainer = styled("div")`
  position: relative;
  height: 315px;
  width: 315px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin: 10px;
  border-radius: 15px;
  align-items: center;
  border: 2px solid transparent;

  @media (max-width: 768px) {
    justify-content: center;
    margin-left: 0px;
    max-width: 90%;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }

  &:hover {
    /* Define styles for hover effect */
    border: 2px solid rgba(55, 133, 191, 0.94);
    box-shadow: 0 0 10px rgba(55, 133, 191, 0.5);
  }
`;

const ProfileImg = styled("img")`
  height: 100%;
  width: 100%;
  // min-height: 315px;
  // min-width: 315px;
  object-fit: cover;
`;

const StyledBio = styled("p")`
  color: #ffffff;
  margin: 5px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  align-self: flex-start;
  max-width: 100%;
  font-size: 13px;
  font-weight: normal;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  width: 100%;
  line-height: 1.2;
  height: 2.4em;
  min-height: 2.4em;
  max-height: 2.4em;
  z-index: 1;
  padding-right: 5px;
`;
