/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavBar } from "../ui/navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import logo from "./logo.png";
import { MentorCardProps, UserData } from "../Mentor/interfaces";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useData } from "../utils/context/DataContext";
import axios, { AxiosError } from "axios";

const ConnectButton = ({
  connectionObject,
  mentee_id,
  mentor_id,
  setConnectionObject,
}: {
  connectionObject: {
    connection_request_id: string | null;
    status: string | null;
  };
  mentee_id: string;
  mentor_id: string;
  setConnectionObject: Function;
}) => {
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });

  const handleConnectionRequest = async (): Promise<{
    id: string;
    mentor_id: string;
    mentee_id: string;
    created_date: Date;
    last_edited_date: Date;
    status: string;
  } | null> => {
    try {
      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest`,
        {
          method: "POST",
          data: { mentor_id, mentee_id },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return connectionPostData;
      // setConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
      return null;
    }
  };

  const deleteConnectionRequest = async (id: string) => {
    try {
      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // setConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  const handleClick = async (add = true, id) => {
    if (!add && id) {
      const answer = confirm(
        "Are you sure you want to remove this connection?"
      );
      if (answer) {
        setConnectionObject({ status: null, connection_request_id: null });
        deleteConnectionRequest(id);
      }
      return;
    } else {
      setConnectionObject({ status: "REQUESTED", connection_request_id: null });
      const requestData = await handleConnectionRequest();
      setConnectionObject({
        ...connectionObject,
        status: requestData?.status,
        connection_request_id: requestData?.id,
      });
    }
  };

  if (!connectionObject.connection_request_id) {
    return (
      <Button
        onClick={() => {
          handleClick(false, null);
        }}
        style={{ backgroundColor: "rgba(55, 133, 191, 0.94)" }}
      >
        Request Connection
      </Button>
    );
  }

  if (connectionObject.status === "DENIED") {
    return (
      <Button variant="outline-danger" disabled>
        Connection Denied
      </Button>
    );
  }

  if (connectionObject.status === "ACCEPTED") {
    return (
      <div>
        <span className="text-primary mr-2">Connected</span>
      </div>
    );
  }

  if (
    connectionObject.status === "REQUESTED" &&
    connectionObject.connection_request_id
  ) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="text-success mr-2">Connection Requested</span>
        <Button
          onClick={() => {
            handleClick(false, connectionObject.connection_request_id);
          }}
          variant="outline-danger"
        >
          undo
        </Button>
      </div>
    );
  }
};

const ApprovalButton = ({ mentorData }: { mentorData: UserData }) => {
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });
  const [approved, setApproved] = useState(mentorData.approved);

  const approveAccount = async (id: string, status: boolean) => {
    try {
      setApproved(status);
      const { data } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${id}`,
        {
          method: "PATCH",
          data: {
            approved: status.toString(),
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  if (approved) {
    return (
      <>
        <span className="text-dark mr-2">Approved</span>
        <Button
          onClick={() => {
            approveAccount(mentorData.id, false);
          }}
          variant="outline-warning"
        >
          undo
        </Button>
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        approveAccount(mentorData.id, true);
      }}
    >
      Approve
    </Button>
  );
};

export default function MenteeCard({ mentorData, isAdmin }: MentorCardProps) {
  const navigate = useNavigate();
  const { setData, data } = useData();
  const handleClick = () => {
    window.location.href = `/profile/${mentorData.id}`;
  };

  const [connectionObject, setConnectionObject] = useState({
    status: mentorData.status,
    connection_request_id: mentorData.connection_request_id,
  });

  return (
    <div style={{ overflowX: "hidden", width: '100%' }}>
      <StyledContainer>
        <Row>
          <ImageCol md={4}>
            <ImageContainer onClick={handleClick}>
              {mentorData.profile_image ? (
                <ProfileImg src={mentorData.profile_image} alt="frontend-app" />
              ) : (
                <img
                  style={{ marginTop: 10 }}
                  src={logo}
                  width={"75%"}
                  height={"75%"}
                />
              )}
            </ImageContainer>
          </ImageCol>
          <ContentCol md={8}>
            {/* Dashboard intro */}
            <div>
              <h2
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >{`${mentorData.first_name} ${mentorData.last_name}`}</h2>
              <p>{mentorData.bio}</p>
              <p>
                {mentorData.classification} - {mentorData.major}
              </p>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "10px 0px",
                }}
              >
                {mentorData?.state && (
                  <div
                    style={{
                      margin: "5px 20px 5px 0px",
                      borderColor: "rgb(0, 119, 181)",
                      color: "rgb(0, 119, 181)",
                      backgroundColor: "white",
                      padding: 3,
                    }}
                  >
                    <h6 style={{ margin: 0 }}>{mentorData.state}</h6>
                  </div>
                )}
                {mentorData?.military && (
                  <div
                    style={{
                      margin: "5px 20px 5px 0px",
                      backgroundColor: "#ff7701",
                      padding: 3,
                      color: "white",
                      borderColor: "#eee",
                    }}
                  >
                    <h6 style={{ margin: 0 }}>{`Military Affiliate`}</h6>
                  </div>
                )}
                {mentorData?.first_generation && (
                  <div
                    style={{
                      margin: "5px 20px 5px 0px",
                      backgroundColor: "#eee",
                      color: "#0077b5",
                      padding: 5,
                    }}
                  >
                    <h6 style={{ margin: 0 }}>{`First Generation Student`}</h6>
                  </div>
                )}
                {mentorData?.adult_learner && (
                  <div
                    style={{
                      margin: "5px 20px 5px 0px",
                      color: "white",
                      backgroundColor: "#0077b5",
                      borderColor: "#0077b5",
                      padding: 5,
                    }}
                  >
                    <h6 style={{ margin: 0 }}>{`21+`}</h6>
                  </div>
                )}
              </div>
            </div>

            {/* {isAdmin ? (
              <div>
                <ApprovalButton mentorData={mentorData} />
              </div>
            ) : (
              <div>
                <ConnectButton
                  connectionObject={connectionObject}
                  mentee_id={data?.id}
                  mentor_id={mentorData.id}
                  setConnectionObject={setConnectionObject}
                />
              </div>
            )} */}
          </ContentCol>
        </Row>
      </StyledContainer>
    </div>
  );
}

// Styled Components

const StyledContainer = styled(Container)`
  margin: 50px 0px;
  padding: 50px;
  width: 100%;
  border-style: solid;
  border-color: #333;
  border-radius: 9px;
  background-color: #eee;
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
  /* Increase box shadow on hover */
  &:hover {
    box-shadow: -1px 2px 4px 4px #aaa;
    -webkit-box-shadow: -1px 2px 4px 4px #aaa;
    -moz-box-shadow: -1px 2px 4px 4px #aaa;
  }
`;

const ImageCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled("div")`
  height: 250px;
  width: 250px;
  background-color: #eee;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border-style: solid;
  border-color: black;
  border-width: 3px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-left: 0px !important;
  }
`;

const ContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProfileImg = styled("img")`
  height: 100%;
  min-height: 230px;
  min-width: 249px;
  object-fit: cover;
`;
